import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import UserDetailsModal from "../Modals/userDetailsModal";

const AccessBar = () => {
  const [openModel, setOpenModal] = useState(false);

  const openusermodel = () => {
    setOpenModal(true);
  };

  return <></>;
};

export default AccessBar;
