import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter}  from "react-router-dom";
import App from './App';
import { Provider } from "react-redux";
import reportWebVitals from './reportWebVitals';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '././assets/css/icofont.min.css';
import '././assets/css/animate.css';
import '././assets/css/style.min.css';
import { AxiosSetup } from './utils/helpers/api.helpers';
AxiosSetup()
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Provider store = { store }>
    <App />
    </Provider>
  
  </React.StrictMode>
);


reportWebVitals();
