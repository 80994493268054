
import {BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';


import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import Home from "./page/home";


import RailwayExam from './page/railway-exam';
import BankingExam from './page/banking-exam';
import SSCExam from './page/ssc-exam';
import Termsandconditions from './component/section/Termsandconditions';
import AppUsagePolicy from './component/section/Appusagepolicy';
import PrivacyPolicy from './component/section/privacy-policy';


function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Home />} />
				

				<Route path="railway-exam" element={<RailwayExam />} />
				<Route path="banking-exam" element={<BankingExam />} />
				<Route path="ssc-exam" element={<SSCExam />} />
				<Route path="terms-and-conditions" element={<Termsandconditions />} />
				<Route path="privacy-policy" element={<PrivacyPolicy />} />
				<Route path="app-usage-policy" element={<AppUsagePolicy />} />

				<Route path="*" element={<ErrorPage />} />
			</Routes>
			
		</BrowserRouter>
	);
}

export default App;
