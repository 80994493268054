import { Link } from "react-router-dom";

const subTitle = "Embrace learning";
const title = "Pathway to Build a Great Career";

const categoryList = [
  {
    imgUrl: "assets/images/category/icon/download.png",
    imgAlt: "LIFE",
    title: "Download LIFE App",
  },
  {
    imgUrl: "assets/images/category/icon/learn.png",
    imgAlt: "category rajibraj91 rajibraj",
    title: "Learn to be the best",
  },
  {
    imgUrl: "assets/images/category/icon/share.png",
    imgAlt: "category rajibraj91 rajibraj",
    title: "Share your experience",
  },
  {
    imgUrl: "assets/images/category/icon/reward.png",
    imgAlt: "category rajibraj91 rajibraj",
    title: "Get Rewarded",
  },
];

const CategoryTwo = () => {
  return (
    <div className="category-section padding-tb section-bg style-2">
      <div className="container">
        <div className="section-header text-center">
          <span className="subtitle">{subTitle}</span>
          <h2 className="title">{title}</h2>
        </div>
        <div className="section-wrapper">
          <div className="row g-4 justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
            {categoryList.map((val, i) => (
              <div className="col" key={i}>
                <div className="category-item text-center">
                  <div className="category-inner">
                    <div className="category-thumb">
                      <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                    </div>
                    <div className="category-content">
                      <h6>{val.title}</h6>

                      <span>{val.desc}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryTwo;
