export const API_BASE_URL =
  
  process.env.API_BASE_URL || "https://api.learningmastery.in/api/v1/";
//process.env.API_BASE_URL || "http://43.205.238.195:4001/";

export const API_PATHS = {
 "visitorslogin" :"/api/v1/visitor/login",
 "getcurrentAffairs":"/api/v1/visitor/currentAffairs/fetch",
 "savecurrentaffairsresponse":"api/v1/visitor/save/current-affair-logs",
 "userFaqs":"/api/v1/user/faq/fetch",


}