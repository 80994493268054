import { Link } from "react-router-dom";
import { playstore } from "../../assets/images/google.png";

const newsTitle = "Download LIFE app to unlock new features.";

const Footer = () => {
  return (
    <div className="news-footer-wrap">
      <div className="news-letter">
        <div className="container">
          <div className="section-wrapper">
            <div className="news-title">
              <h3>{newsTitle}</h3>
            </div>
            <div className="news-form">
              <a href="https://lifemobileapp.page.link/HrGD">
                <img
                  src="assets/images/google.png"
                  style={{ height: "50px" }}
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <p>
                &copy; 2024 All rights reserved, Product by{" "}
                <a
                  href="https://octaloptimum.com/"
                  target="_blank"
                  style={{ color: "#fff" }}
                >
                  Octal Optimum
                </a>
              </p>
            </div>
            <div className="col-md-5 justify-content-end">
              <Link
                to="/terms-and-conditions"
                target="_blank"
                style={{ color: "#f4f4f4" }}
              >
                Terms and Conditions
              </Link>
              &nbsp; &nbsp; &nbsp;
              <Link
                to="/app-usage-policy"
                target="_blank"
                style={{ color: "#f4f4f4" }}
              >
                App Usage Policy
              </Link>
              &nbsp; &nbsp; &nbsp;{" "}
              <Link
                to="/privacy-policy"
                target="_blank"
                style={{ color: "#f4f4f4" }}
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
