const subTitle = "Benifits";
const title = "Benefits of using LIFE Learning App";

const aboutList = [
  {
    imgUrl: "assets/images/about/icon/01.jpg",
    imgAlt: "LIFE",
    title: "Flexibility and Convenience",
    desc: "Learn anytime and anywhere, manage learning in a busy schedule.",
  },
  {
    imgUrl: "assets/images/about/icon/02.jpg",
    imgAlt: "LIFE",
    title: "Personalized Learning",
    desc: "Tailored content based on user preferences, skill levels, and progress.",
  },
  {
    imgUrl: "assets/images/about/icon/03.jpg",
    imgAlt: "LIFE",
    title: "Self-Paced Learning",
    desc: "Allows learners to progress at their own speed, ensuring better understanding.",
  },
  {
    imgUrl: "assets/images/about/icon/01.jpg",
    imgAlt: "LIFE",
    title: "Engaging Content",
    desc: "Interactive formats for an enhanced learning experiences.",
  },
  {
    imgUrl: "assets/images/about/icon/04.png",
    imgAlt: "LIFE",
    title: "Real-Time Progress Tracking",
    desc: "Monitor performance and achievements through dashboards and metrics.",
  },
  {
    imgUrl: "assets/images/about/icon/03.jpg",
    imgAlt: "LIFE",
    title: "Updates and Notifications",
    desc: "Keep learners informed of new resources, assignments, or course updates.",
  },
];

const About = () => {
  return (
    <div className="about-section">
      <div className="container">
        <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-end flex-row-reverse">
          <div className="col">
            <div className="about-right padding-tb">
              <div className="section-header">
                <span className="subtitle">{subTitle}</span>
                <h2 className="title">{title}</h2>
              </div>
              <div className="section-wrapper">
                <ul className="lab-ul">
                  {aboutList.map((val, i) => (
                    <li key={i}>
                      <div className="sr-left">
                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                      </div>
                      <div className="sr-right">
                        <h5>{val.title}</h5>
                        <p>{val.desc}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="about-left">
              <div className="about-thumb">
                <img src="assets/images/about/01.png" alt="about" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
